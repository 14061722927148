<template>
  <div class="page notice-page" :class="{ 'more-depth': $route.meta && $route.meta.headerType === 'more-depth' }">
    <div class="container">
      <div class="page-header">
        <div class="title">{{ item.title }}</div>
        <div class="secondary">{{ item.date }}</div>
      </div>

      <div class="page-body">
        <notice-single :block-id="item.blockId" />
      </div>
    </div>
  </div>
</template>

<script>
import NoticeSingle from '@/components/resources/notice/NoticeSingle.vue';

export default {
  components: {
    NoticeSingle
  },
  computed: {
    item() {
      return this.$store.getters['custom/notice']({ id: this.$route.params.id }) || [];
    },
  },
  mounted() {
    console.log( this.item );
  }
};
</script>

<style lang="scss" scoped>
.page.notice-page {

  .page-header {
    display: block;
    padding: 2 * $unit;
    border-bottom: 1px solid $color-gray-3;
    z-index: 1;
    display: block;

    .title {
      font-size: unit(22);
      font-weight: 500;
    }

    .secondary {
      font-size: unit(14);
      line-height: unit(20);
      color: #878d91;
    }
  }

  .page-body {
    padding: 0;
    flex: 1;
    position: relative;
  }
}
</style>
