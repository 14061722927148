<template>
  <div class="resource notice-single">
    <notion-renderer :block-id="blockId" />
  </div>
</template>

<script>
export default {
  props: ["blockId"]
}
</script>

<style lang="scss" scoped>
.notice-single {
  position: absolute;
  width: 100%; height: 100%;
  left: 0; top: 0; right: 0; bottom: 0;
  display: flex;
  .notion-renderer {
    width: 100%; 
    flex: 1;
    display: flex;
    padding: 0; margin: 0;

    > .container {
      padding: 0; margin: 0;
      flex: 1;
      width: 100%; 
    }
  }
}
</style>